import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";

// markup
export default function ReservedPage() {
  return (
    <Layout>
      <Helmet title="VIP Dine - Cookie Policy" />
      <section className="section hero reserved-hero sub-page">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>
                <span className="product-name yellow">VIP Dine</span> - Cookie
                Policy
              </h1>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Cookie Policy</h1>
              <div className="divider"></div>

              <div id="cookiefirst-policy-page"></div>

              <div>
                This cookie policy has been created and updated by{" "}
                <a href="https://cookiefirst.com">CookieFirst.com</a>.
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
